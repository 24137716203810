// Global packages and components
import { clsx } from 'clsx'

// Types
import type { ReactNode } from 'react'

interface Props {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8'
  children: ReactNode
  className?: string
  bold?: boolean
  semiBold?: boolean
  font?: boolean
}

export const headingSizes = {
  h1: 'text-5xl lg:text-8.5xl',
  h2: 'text-4.5xl lg:text-6xl',
  h3: 'text-4xl lg:text-5xl',
  h4: 'text-3xl lg:text-4.5xl',
  h5: 'text-2xl lg:text-3xl',
  h6: 'text-xl lg:text-2xl',
  h7: 'text-base lg:text-xl',
  h8: 'text-md lg:text-lg',
}

// Main export
const Heading = ({
  tag,
  size,
  children,
  className,
  bold = true,
  semiBold = false,
  font = true,
}: Props) => {
  const classes = clsx(className, {
    'font-bold': bold,
    'font-semibold': semiBold && !bold,
    'font-program': font,
    [headingSizes.h1]: size == 'h1',
    [headingSizes.h2]: size == 'h2',
    [headingSizes.h3]: size == 'h3',
    [headingSizes.h4]: size == 'h4',
    [headingSizes.h5]: size == 'h5',
    [headingSizes.h6]: size == 'h6',
    [headingSizes.h7]: size == 'h7',
    [headingSizes.h8]: size == 'h8',
  })

  return tag == 'h1' ? (
    <h1 className={classes}>{children}</h1>
  ) : tag == 'h2' ? (
    <h2 className={classes}>{children}</h2>
  ) : tag == 'h3' ? (
    <h3 className={classes}>{children}</h3>
  ) : tag == 'h4' ? (
    <h4 className={classes}>{children}</h4>
  ) : tag == 'h5' ? (
    <h5 className={classes}>{children}</h5>
  ) : tag == 'h6' ? (
    <h6 className={classes}>{children}</h6>
  ) : (
    children
  )
}

export default Heading
